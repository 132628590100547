/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
/* Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
/* Reset */
body{
    font-family: 'Open Sans', sans-serif;
    scroll-behavior: smooth;
}

a{
    text-decoration: none!important;
    color: currentColor!important;

    &:hover{
        color: currentColor!important;
    }
}
/* Brand Color Variables */
$brand-01: #6191e2;
$brand-02: #fecd31;
$brand-03: #c6e1fc;
$brand-04: #3a539b;
/* UI Color Variables */
$ui-01: #ffffff;
$ui-02: #f9f9fc;
$ui-03: #f3f3f3;
/* Text Color Variables */
$text-01: #343434;
$text-02: #5a6872;
/* Support Color Variables */
$error: #f4516c;
$error-light:#fbb0bd;
$success: #34bfa3;
$success-light:#bbfff1;
$warning: #ffa322;
$warning-light:#ffdba9;
$info: #36a3f7;
$info-light:#b8dffd;

/* classes */
.btn{
    background-color: #6191e2!important;
    padding: 6px 12px;
    color: white!important;
    font-weight: 500!important;
    letter-spacing: 0.2px!important;
    &:hover{
        color: white !important;
        background-color: #76a2ec!important;
        font-weight: 500!important;
        letter-spacing: 0.2px!important;
    }
    
    &-inverse{
        background-color: $brand-02!important;
        color: $brand-04!important;
        font-weight: 500!important;
        letter-spacing: 0.2px!important;
        &:hover{
            background-color: #f0c22e !important;
            color: $brand-04 !important;
            font-weight: 500!important;
            letter-spacing: 0.2px!important;
        }


    }
}

.fs{
    &-1{
        font-size: 44px !important;
    }
    &-2{
        font-size: 34px !important;
    }
    &-3{
        font-size: 24px !important;
    }
    &-4{
        font-size: 20px !important;
    }
    &-5{
        font-size: 18px !important;
    }
    &-6{
        font-size: 16px !important;
    }
    &-7{
        font-size: 14px !important;
    }
    &-8{
        font-size: 12px !important;
    }
    &-9{
        font-size: 10px !important;
    }
    &-10{
        font-size: 8px !important;
    }
}

.fw{
    &-black{
        font-weight: 800;
    }
    &-bold{
        font-weight: 700;
    }
    &-semi-bold{
        font-weight: 600;
    }
    &-medium{
        font-weight: 500;
    }
    &-regular{
        font-weight: 400;
    }
    &-light{
        font-weight: 300;
    }
}

.y-underline{
    position: relative;
    &::after{
        display: block;
        content: '';
        position: absolute;
        top: 27px;
        right: 0;
        left: 0;
        background-color: #FFEAA6;
        height: 15px;
        z-index: -2;
        @media only screen and (min-width: 375px) {
                    position: absolute;
                    height: 15px;
                    width: 100%;
                    top: 26px!important;
                    background-color: #FFEAA6;
                }
            
                @media only screen and (max-width: 768px) {
                    position: absolute;
                    height: 15px;
                    width: 100%;
                    top: 26px!important;
                    background-color: #FFEAA6;
                }
    }
}

.y-underline-pricing{
    position: relative;
    z-index: 50!important;
    &::after{
        display: block;
        content: '';
        position: absolute;
        top: 15px;
        right: 0;
        left: 0;
        background-color: #FFEAA6;
        height: 15px;
        z-index: -2!important;
        @media only screen and (min-width: 375px) {
                    position: absolute;
                    height: 15px;
                    width: 100%;
                    top: 14px!important;
                    background-color: #FFEAA6;
                }
            
                @media only screen and (max-width: 768px) {
                    position: absolute;
                    height: 15px;
                    width: 100%;
                    top: 14px!important;
                    background-color: #FFEAA6;
                }
    }
}

.w-m-c{
    width: max-content;
}






    .ocm-panel-container{
        border-radius: 0.4rem!important;
        .ocm-panel__header{
            background-color: #f3f3f3!important;
            border-top-right-radius: 0.4rem!important;
            border-top-left-radius: 0.4rem!important;
            color: white;
        }

        .ocm-panel__buttons{
            border-radius: 0.4rem;
        }

        .ocm-panel__buttons button.ocm-panel__buttons--accept {
            background-color: #fecd31!important;
            color: #3a539b;
            font-weight: 600;
            letter-spacing: .5px;
            text-align: center;
            
        }

        .ocm-panel__buttons button.ocm-panel__buttons--decline {
            background-color: #f3f3f3 !important;
            color: #1c1c1c;
        }

        .ocm-panel__buttons button.ocm-panel__buttons--manage {
            background-color: #6191e2 !important;
            color: #fff;
            border: 1px solid transparent;
        }
    }
    
 
.footer-list{

    &__item{
        a{
            opacity: 0.5;
            text-decoration: none;
            color: #1c1c1c !important;
            &:hover{
                opacity: 100%;
                transition: 125ms ease-in-out;
            }
        }
        

        
    }
}

.footer{
    border-top: 2px solid rgba($color: #6191e2, $alpha: 0.2);
}   

.anchor{
    color: #6191e2 !important;
    text-decoration: underline !important;
}


/* 
#6191e2;
$brand-02: #fecd31; */
